import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {CardElement, Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51NkUrIH47Ojsnfv18ZLqhCORvcieLZp1K0UTRpcf6ffcNtMtrh29UY6OR8OeXdO4SyxSmAhJHpzoLGrsv7uYqw9Q00NsxyoNut')

const BecomeMember = () => {
    document.title = "The Ark VIP Club";

    const [paymentSecret, setPaymentSecret] = useState(null)
    const [amount, setAmount] = useState(null)
    
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_API_URL+'/create-become-member-payment-intent', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while creating payment form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            if (data?.status == 'success')
            {
                setPaymentSecret(data.secret)
                setAmount(data.amount)
            }
        })
        .catch(error => {
        })
    }, [])
    
    return (
        <section className="banner_section subpage_section">
            <div className="container" data-aos="fade-in" data-aos-duration="500">
                <div className="banner_text">
                    <h1>Become a Member</h1>
                    <p>Great coffee has always been a passion of mine now I want to share that passion with you. The coffee culture has been around for over 30 years and it is hard to find a good one in the UK. Coffee is my obsession and I thought why not introduce my own range of New York coffee to encourage the nation to drink fantastic quality coffee this is why I have teamed up with The Ark VIP members club.</p>
                    <p>Become a member of The Ark VIP Club now{amount ? ' in just '+amount : ''}.</p>
                </div>

                {paymentSecret ? (
                    <Elements stripe={stripePromise} options={{
                        clientSecret: paymentSecret
                    }}>
                        <BecomeMemberForm amount={amount} />
                    </Elements>
                ) : (
                    'Loading...'
                )}
            </div>

            {/* <div>
                <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" fill="#f6f4fe" />
                </svg>
            </div> */}
        </section>
    );
}

const BecomeMemberForm = ({amount}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [stripeStatus, setStripeStatus] = useState('loading');

    const cardRef = useRef();
    
    const [referralCodeLoading, setReferralCodeLoading] = useState(false)
    const [referralCodeMessage, setReferralCodeMessage] = useState(null)
    const [referralCodeFound, setReferralCodeFound] = useState(null)

    const [referralCode, setReferralCode] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');

    const handleReferralCode = (e) => {
        e.preventDefault()

        if (!referralCode) return;
        
        setReferralCodeLoading(true)
        setReferralCodeMessage(null)
        setReferralCodeFound(null)

        fetch(process.env.REACT_APP_BASE_API_URL+'/verify-referral-code/'+referralCode, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while verifying referral code, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setReferralCodeLoading(false);

            if (data?.status == 'success')
            {
                setReferralCodeFound(data.found)
                setReferralCodeMessage(data.message);
            }
            else
            {
                setReferralCodeMessage('There was some error while verifying referral code, please try again.');
            }
        })
        .catch(error => {
            setReferralCodeMessage(error.message);
            setReferralCodeLoading(false);
        })
    }

    useEffect(() => {
        if (!referralCode)
        {
            setReferralCodeFound(null)
            setReferralCodeMessage(null)
        }
    }, [referralCode])
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (stripeStatus !== 'loaded') return

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }
      
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        if (referralCode) formData.append("referral_code", referralCode)
        formData.append("name", name)
        formData.append("email", email)
        formData.append("address", address)
        //formData.append("charity", charity)
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/verify-become-member-data', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while processing, please try again.');
            }

            return response.json();
        })
        .then(data => {
            if (data?.status == 'success')
            {
                if (referralCode)
                {
                    setReferralCodeFound(data.has_referral_code)
                }

                (async () => {
                    const result = await stripe.confirmPayment({
                        elements,
                        redirect: 'if_required'
                    });
            
                    if (result.error) {
                        cardRef.current.scrollIntoView();
                        setError(result.error.message);
                        setIsLoading(false);
                    } else {
                        let formData = new FormData();
                        if (referralCode) formData.append("referral_code", referralCode)
                        formData.append("name", name)
                        formData.append("email", email)
                        formData.append("address", address)
                        
                        fetch(process.env.REACT_APP_BASE_API_URL+'/create-become-member-capture-payment/'+result.paymentIntent.id, {
                            method: 'POST',
                            headers: { 
                                "Accept": "application/json",
                            },
                            body: formData
                        })
                        .then(response => {
                            if (!response.ok) {
                                throw Error('There was some error while processing, please try again.');
                            }

                            return response.json();
                        })
                        .then(data => {
                            if (data?.status == 'success')
                            {
                                setSuccess(true)
                            }
                            else if (data?.status == 'error' && data?.message)
                            {
                                setError(data.message);
                            }
                            else
                            {
                                setError('There was some error while processing, please try again.');
                            }
                        })
                        .catch(error => {
                            setError(error.message);
                        })
                        .finally(() => {
                            cardRef.current.scrollIntoView();
                            setIsLoading(false);
                        })
                    }
                })()
            }
            else if (data?.status == 'validation_error')
            {
                setIsLoading(false);
                cardRef.current.scrollIntoView();
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setIsLoading(false);
                cardRef.current.scrollIntoView();
                setError(data.message);
            }
            else
            {
                setIsLoading(false);
                cardRef.current.scrollIntoView();
                setError('There was some error while processing, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }

    return (
        <>
            <div ref={cardRef} style={{ marginTop: '-80px', position: 'absolute' }}></div>
            {success ? (
                <div className='member-success'>
                    <h5>Thank you for becoming a member.</h5>
                    <p>We will create your account and send your referral code to your email address.</p>
                    <p>Thank you for being an exceptional member of Ark VIP Club.</p>
                </div>
            ) : (
                <>
                    <form onSubmit={handleSubmit} className='max-400px'>
                        <h4 className='form-title'>Enter your information</h4>
                        { error && <div className="alert-msg-danger"><p className="mb-0">{ error }</p></div> }
                        
                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <p className="mb-0">There was some error in submitted information, please try again.</p>
                                <ul>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <p className='form-label'>Referral code</p>
                        <div style={{ marginBottom: '20px' }}>
                            <div className='become-member-referral-code-container'>
                                { referralCodeFound === true ? (
                                    <>
                                        <div className='applied-referral-code'>
                                            <p><span>"{referralCode}"</span> code applied</p>
                                        </div>
                                        <button 
                                            type='button' 
                                            onClick={() => {
                                                setReferralCodeMessage(null)
                                                setReferralCodeFound(null)
                                                setReferralCode('')
                                            }} 
                                            className="become-member-btn btn-small">REMOVE</button>
                                    </>
                                ) : (
                                    <>
                                        <input
                                            value={referralCode}
                                            onChange={ (e) => setReferralCode(e.target.value) }
                                            onKeyPress={e => { if (e.key === 'Enter') { handleReferralCode(e) } }}
                                            className='form-control'
                                            placeholder='Enter referral code'
                                            style={{ fontFamily: 'monospace', textTransform: 'uppercase' }}
                                            />
                                    <button 
                                        type='button' 
                                        disabled={referralCodeLoading || !referralCode}
                                        onClick={handleReferralCode} 
                                        className="become-member-btn btn-small">
                                        {referralCodeLoading ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100"><circle cx="50" cy="50" r="32" fill="none" stroke="#f6f4fe" strokeDasharray="50.26548245743669 50.26548245743669" strokeLinecap="round" strokeWidth="8"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle></svg>
                                        ) : 'APPLY'}</button>
                                    </>
                                )}
                            </div>
                            { referralCodeFound === false && <div className="alert-msg-danger mt-2 mb-0"><p className="mb-0">{ referralCodeMessage }</p></div> }
                        </div>

                        <p className='form-label'>Your full name</p>
                        <input
                            name='name'
                            value={name}
                            onChange={ (e) => setName(e.target.value) }
                            className='form-control'
                            placeholder='Enter your full name'
                            required />
                            
                        <p className='form-label'>Your email address</p>
                        <input
                            name='email'
                            value={email}
                            onChange={ (e) => setEmail(e.target.value) }
                            className='form-control'
                            placeholder='Enter your email address'
                            type='email'
                            required />

                        <p className='form-label'>Your complete address</p>
                        <textarea
                            name='address'
                            value={address}
                            onChange={ (e) => setAddress(e.target.value) }
                            className='form-control'
                            placeholder='Enter your address'
                            rows={3} 
                            required ></textarea>

                        <div style={{marginBottom:'20px'}}>
                            <PaymentElement onReady={() => setStripeStatus('loaded')} onLoadError={() => setStripeStatus('error')} />
                        </div>

                        <button type='submit' disabled={isLoading || stripeStatus !== 'loaded'} className="become-member-btn btn-small">
                            {isLoading ? 'Processing...' : (
                                <>
                                    {stripeStatus == 'loading' ? 'Loading payment form...': null}
                                    {stripeStatus == 'loaded' ? 'Pay '+amount+' to become a member' : null}
                                    {stripeStatus == 'error' ? 'Error loading payment': null}
                                </>
                            )}
                        </button>
                    </form>
                </>
            )}
        </>
    );
}
 
export default BecomeMember;