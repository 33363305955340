import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const BankCreate = () => {
    document.title = "Provide Bank Details | Ark Club";
    
    let navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [link, setLink] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
    
        setIsLoading(true);
        setError(false);
        setSuccess(false);
        setLink(null)

        fetch(process.env.REACT_APP_BASE_API_URL+'/create-stripe-link/'+(code ?? 'invalid'), {
            method: 'GET',
            headers: { 
                "Accept": "application/json",
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while processing, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message)
                setLink(data.link)
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while processing, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }, []);

    return (
        <section className="banner_section subpage_section">
            <div className="container" data-aos="fade-in" data-aos-duration="500">
                <div className="banner_text">
                    <h2>You're almost there! Provide bank details</h2>
                    
                    {isLoading ? (
                        <>
                            <img src={'/loader.svg'} style={{width: '40px'}} className='mb-1' />
                            <p className="mb-0">Please wait, creating your form link...</p>
                        </>
                    ) : (
                        <>
                            { error && <div className="alert-msg-danger"><p className='mb-0'>{ error }</p></div> }

                            { success && link && (
                                <>
                                    <p className='mb-2'>{ success }</p>
                                    <a href={link} className='become-member-btn btn-small'>Fill Bank Details Form</a>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}
 
export default BankCreate;