import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const BankSuccess = () => {
    document.title = "Provide Bank Details | Ark Club";
    
    let navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
    
        setIsLoading(true);
        setError(false);
        setSuccess(false);

        fetch(process.env.REACT_APP_BASE_API_URL+'/stripe-verify/'+(code ?? 'invalid'), {
            method: 'GET',
            headers: { 
                "Accept": "application/json",
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while processing, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(true)
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while processing, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }, []);

    return (
        <section className="banner_section subpage_section">
            <div className="container" data-aos="fade-in" data-aos-duration="500">
                <div className="banner_text">
                    <h2>{isLoading ? 'Verifying details' : success ? 'Bank details received!' : 'Bank details not received'}</h2>
                    
                    {isLoading ? (
                        <>
                            <img src={'/loader.svg'} style={{width: '40px'}} className='mb-1' />
                            <p className="mb-0">Please wait, verifying your bank details...</p>
                        </>
                    ) : (
                        <>
                            { error && <div className="alert-msg-danger"><p className='mb-0'>{ error }</p></div> }
                            
                            { success && (
                                <>
                                    <p className='mb-1'>Thank you for submitting bank details.</p>
                                    <p className="mb-1">When your 12th connection becomes a member, we will your well-deserved rewards directly to your account.</p>
                                    <p className="mb-0">Thank you for being an exceptional member of Ark VIP Club and for sharing your positive influence with others.</p>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}
 
export default BankSuccess;