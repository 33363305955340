import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from "./Header";
import Footer from "./Footer";

import Home from "./Home";
import Shop from "./Shop";
import BecomeMember from "./BecomeMember";
import NotFound from "./NotFound";
import BankCreate from "./BankCreate";
import BankSuccess from "./BankSuccess";

const App = () => {
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        AOS.init({
            once: true
        });
    }, [])

    return ( 
        <>
            <Routes>
                <Route element={<AppLayout />} >
                    <Route path="/" element={<Home />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/become-member" element={<BecomeMember />} />
                    <Route path="/bank" element={<BankCreate />} />
                    <Route path="/bank-success" element={<BankSuccess />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
}

const AppLayout = () => {
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 250) {
                setIsHeaderFixed(true);
            } else {
                setIsHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`page_wrapper`}>
            <Header isHeaderFixed={isHeaderFixed} />
            
            <Outlet />

            <Footer />
        </div>
    )
};
 
export default App;