import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';

const Header = ({isHeaderFixed}) => {
    const currentPath = useLocation().pathname.split('/')[1];
    
    return (
        <header className={`${isHeaderFixed ? 'fix_style fixed' : ''}`}> 
            <div className="container">
                <Navbar expand="md" fixed="top">
                    {currentPath ? (
                        <Navbar.Brand as={Link} to="/">
                            <img src="https://www.thearkvipclub.com/logo.svg" alt="The Ark Vip Club Logo"/>
                        </Navbar.Brand>
                    ) : (
                        <Navbar.Brand href='#'>
                            <img src="https://www.thearkvipclub.com/logo.svg" alt="The Ark Vip Club Logo"/>
                        </Navbar.Brand>
                    )}
                    {/* <Nav className="ml-auto d-md-none toggle-nav">
                        <Nav.Item>
                            <img src='/images/mind.png' className='header-sponser'/>
                        </Nav.Item>
                    </Nav> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="navbar-toggler-icon">
                            <div className="toggle-wrap">
                                <span className="toggle-bar"></span>
                            </div>
                        </span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="main-nav">
                            <Nav.Item>
                                {currentPath ? (
                                    <Link to="/" className="nav-link">Home</Link>
                                ) : (
                                    <a href="#" className="nav-link">Home</a>
                                )}
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/shop" className="nav-link">Shop</Link>
                            </Nav.Item>
                            <Nav.Item>
                                {currentPath ? (
                                    <Link to="/#about" className="nav-link">About Us</Link>
                                ) : (
                                    <a className="nav-link" href="#about">About Us</a>
                                )}
                            </Nav.Item>
                            {/* <Nav.Item>
                                {currentPath ? (
                                    <Link to="/#what-we-have" className="nav-link">What We Have</Link>
                                ) : (
                                    <a className="nav-link" href="#what-we-have">What We Have</a>
                                )}
                            </Nav.Item>
                            <Nav.Item>
                                {currentPath ? (
                                    <Link to="/#faqs" className="nav-link">FAQs</Link>
                                ) : (
                                    <a className="nav-link" href="#faqs">FAQs</a>
                                )}
                            </Nav.Item> */}
                            {/* <Nav.Item className='d-sm-none'>
                                <img src='/images/mind.png' className='header-sponser'/>
                            </Nav.Item> */}
                        </Nav>
                        {/* <Nav className="ml-auto d-none d-md-block">
                            <Nav.Item>
                                <img src='/images/mind.png' className='header-sponser'/>
                            </Nav.Item>
                        </Nav> */}
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </header>
    );
}
 
export default Header;