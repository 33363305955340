import { Link, useNavigate } from 'react-router-dom';
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';

const Home = () => {
    document.title = "The Ark VIP Club";

    let navigate = useNavigate();

    const [sponserZoomed, setSponserZoomed] = useState(false)

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="banner_text">
                                <div className={`home-sponser ${sponserZoomed ? 'zoom' : ''}`}>
                                    <img src='/images/mind.png' className='image-sponser' onClick={() => setSponserZoomed(current => !current)} />
                                    <img src="/cross.svg" className='close-sponser' onClick={() => setSponserZoomed(false)} />
                                </div>
                                <div data-aos="fade-right" data-aos-duration="1000">
                                    <h1>The Ark VIP Club<br />Healthy New York Mushroom Coffee</h1>
                                    <p>Great coffee has always been a passion of mine now I want to share that passion with you.</p>
                                </div>
                            </div>
                            <Link to="/become-member" className="become-member-btn mr-2" data-aos="fade-in" data-aos-duration="1000">Become a Member</Link>
                            <Link to="/shop" className="shop-here-btn" data-aos="fade-in" data-aos-duration="1000">Shop here</Link>
                        </div>

                        <div className="col-lg-1"></div>
                        <div className="col-lg-4 col-md-12" data-aos="fade-in" data-aos-duration="1000">
                            <div className="banner_image">
                                <img className="moving_animation" src="/images/banner-image.png" alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div>
                    <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" fill="#f6f4fe" />
                    </svg>
                </div> */}
            </section>

            <div id="about" style={{ position: 'relative', top: '-80px' }}></div>
            <section className="row_am about_app_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1000">
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src="/images/about-1.png" alt="About" />
                                </div>
                                <div className="screen_img zindex-2">
                                    <img className="moving_animation" src="/images/about-2.jpeg" alt="About" />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                                    <h2><span>About</span> The Ark VIP Club</h2>
                                    <p>The Ark VIP club is a unique members club where you can earn fantastic commissions by becoming one of our distributors and introducing just 2 new members. When you become a distributor for New York Coffee you will be introducing ‘New to the UK’ Healthy New York Mushroom Coffee.</p>
                                    <p>The coffee culture has been around for over 30 and there has been many changes over the years. I thought why not introduce a healthy mushroom coffee to encourage the nation to drink fantastic quality healthy coffee with many benefits. Many studies suggest that incorporating mushrooms into coffee can help reduce inflammation, increase energy levels, improve cognitive function and even boost immune system support.</p>
                                </div>

                                <Link to="/become-member" className="become-member-btn mr-2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">Become a Member</Link>
                                <Link to="/shop" className="shop-here-btn" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">Shop here</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div id="what-we-have" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am modern_ui_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="ui_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                                    <h2><span>What </span> We Have</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <ul className="design_block">
                                    <li data-aos="fade-up" data-aos-duration="1000">
                                        <h4>Coffee beans</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1000">
                                        <h4>Coffee cups</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    </li>
                                </ul>
                                
                                <Link to="/become-member" className="become-member-btn mr-2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">Become a Member</Link>
                                <Link to="/shop" className="shop-here-btn" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">Shop here</Link>
                            </div>
                        </div>

                        <div className="col-lg-5 mt-3 mt-lg-0 d-flex d-lg-block">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1000">
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src="/images/coffee-heart.png" alt="Why choose us"/>
                                </div>
                                <div className="screen_img moving_animation zindex-2">
                                    <img src="https://images.pexels.com/photos/1005766/pexels-photo-1005766.jpeg?auto=compress&cs=tinysrgb&w=500&h=500&dpr=1" alt="Safe"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="faqs" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                        <h2>Frequently Asked <span>Questions</span></h2>
                        <p>Need help getting started with The Ark VIP Club? Our FAQs have got you covered<br/> by providing clear and concise answers to all your questions.</p>
                    </div>

                    <div className="faq_panel">
                        <Accordion defaultActiveKey="0">
                            <div className="card" data-aos="fade-up" data-aos-duration="1000">
                                <div className="card-header">
                                    <CustomAccordianToggle eventKey="0">In which states The Ark VIP Club available?</CustomAccordianToggle>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                    <div className="card-body">
                                        <p>The Ark VIP Club is available currently in California, Texas and Florida.</p>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1000">
                                <div className="card-header">
                                    <CustomAccordianToggle eventKey="1">How can I purchase coffee?</CustomAccordianToggle>
                                </div>
                                <Accordion.Collapse eventKey="1">
                                    <div className="card-body">
                                        <p>You can purchase your coffee after becoming a member.</p>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </section> */}
        </>
    );
}

function CustomAccordianToggle({ children, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <h2 className="mb-0" onClick={decoratedOnClick}>
            <button type="button" className={`btn btn-link ${isCurrentEventKey ? 'active' : ''}`}>
                {children}
                {isCurrentEventKey ? (
                    <img src="/images/icon-minus.svg" alt="View less" className="icon_faq minus" />
                ) : (
                    <img src="/images/icon-plus.svg" alt="View more" className="icon_faq plus" />
                )}
            </button>
        </h2>
    );
}

export default Home;