import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="top_footer" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 col-12">
                            <div className="abt_side">
                                <div className="logo"><a href="https://www.thearkvipclub.com"><img src="https://www.thearkvipclub.com/logo.svg" alt="Ash Budget Logo"/></a></div>
                                <p className="footer-about">Great coffee has always been a passion of mine now I want to share that passion with you.</p>
                                <ul className="contact-links">
                                    <li><a href="mailto:info@thearkvipclub.com"><img src="/images/icon-email.svg" alt="Email"/>info@thearkvipclub.com</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12 col-12">
                            <div className="links useful-links">
                                <h3>Useful Links</h3>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/shop">Shop Here</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom_footer">
                <div className="container">
                    <p>© Copyrights 2023. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
 
export default Footer;