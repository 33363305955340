import { Link } from 'react-router-dom';

const Shop = () => {
    document.title = "The Ark VIP Club";
    
    return (
        <>
            <section className="banner_section full_page">
                <div className="container">
                    <div className="text-center"  data-aos="fade-in" data-aos-duration="500">
                        <div className="banner_text">
                            <h1>Coming Soon</h1>
                            <p>We are working on it...</p>
                        </div>
                        <Link to="/" className="shop-here-btn">Back to Home</Link>
                    </div>
                </div>

                {/* <div>
                    <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" fill="#f6f4fe" />
                    </svg>
                </div> */}
            </section>
        </>
    );
}
 
export default Shop;